const React = require("react");
const { addScript } = require("./src/utils/addScript");

const loadTawk = () => {
  if (typeof window !== "undefined") {
    window?.Tawk_API?.showWidget();
  }
};
exports.onClientEntry = () => {
  window.onload = () => {
    addScript(
      "https://embed.tawk.to/630e212237898912e9663491/1gbnj2snh",
      loadTawk
    );
  };
};

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return <>{element}</>;
};
