exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-hire-developers-backend-developers-js": () => import("./../../../src/pages/hire-developers/backend-developers.js" /* webpackChunkName: "component---src-pages-hire-developers-backend-developers-js" */),
  "component---src-pages-hire-developers-cloud-js": () => import("./../../../src/pages/hire-developers/cloud.js" /* webpackChunkName: "component---src-pages-hire-developers-cloud-js" */),
  "component---src-pages-hire-developers-dotnet-js": () => import("./../../../src/pages/hire-developers/dotnet.js" /* webpackChunkName: "component---src-pages-hire-developers-dotnet-js" */),
  "component---src-pages-hire-developers-frontend-developers-js": () => import("./../../../src/pages/hire-developers/frontend-developers.js" /* webpackChunkName: "component---src-pages-hire-developers-frontend-developers-js" */),
  "component---src-pages-hire-developers-index-js": () => import("./../../../src/pages/hire-developers/index.js" /* webpackChunkName: "component---src-pages-hire-developers-index-js" */),
  "component---src-pages-hire-developers-mobile-developers-js": () => import("./../../../src/pages/hire-developers/mobile-developers.js" /* webpackChunkName: "component---src-pages-hire-developers-mobile-developers-js" */),
  "component---src-pages-hire-developers-salesforce-js": () => import("./../../../src/pages/hire-developers/salesforce.js" /* webpackChunkName: "component---src-pages-hire-developers-salesforce-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-details-[id]-js": () => import("./../../../src/pages/job-details/[id].js" /* webpackChunkName: "component---src-pages-job-details-[id]-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-design-js": () => import("./../../../src/pages/services/design.js" /* webpackChunkName: "component---src-pages-services-design-js" */),
  "component---src-pages-services-development-js": () => import("./../../../src/pages/services/development.js" /* webpackChunkName: "component---src-pages-services-development-js" */),
  "component---src-pages-services-support-maintenance-js": () => import("./../../../src/pages/services/support-maintenance.js" /* webpackChunkName: "component---src-pages-services-support-maintenance-js" */)
}

