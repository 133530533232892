export function addScript(url, callback) {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    if (callback) {
      script.onload = function () {
        callback();
      };
    }
  }